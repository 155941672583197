<template>
  <a-modal
    ref="rejectmessage"
    v-model:visible="isVisible"
    title="Confirm Delete"
    centered
    :width="520"
    :footer="null"
    @cancel="close"
  >
    <div class="reject-modal-content">
      <div class="ask-text">
        Would you like to delete this file "{{ fileDetail.file_name }}"?
      </div>
      <div class="action-button-modal">
        <a-button
          size="large"
          type="default"
          class="cancel-button"
          :outlined="true"
          @click="close"
        >
          Cancel
        </a-button>
        <a-button
          size="large"
          type="default"
          class="reject-button"
          @click="removeExport"
          >Delete</a-button
        >
      </div>
    </div>
  </a-modal>
</template>

<script>
import { toRefs, watch, ref } from 'vue';
import api from '@/services/api';

export default {
  props: {
    fileDetail: Object,
    visible: Boolean,
  },
  setup(props, { emit }) {
    const { visible, fileDetail } = toRefs(props);
    const isVisible = ref(visible.value);
    watch(
      () => visible.value,
      () => {
        isVisible.value = visible.value;
      }
    );

    const close = () => {
      emit('close', { isReload: false });
    };

    const removeExport = () => {
      const { file_id } = fileDetail.value;
      api.removeExport(file_id).then(() => {
        // get status export
        emit('close', { isReload: true });
      });
    };

    return {
      isVisible,
      close,
      removeExport,
    };
  },
};
</script>

<style lang="scss" scoped>
.reject-modal-content {
  .ask-text {
    color: #5a5f7d;
    font-size: 15px;
    margin-bottom: 40px;
  }
  .action-button-modal {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      font-weight: 400;
      font-size: 14px;
      height: 44px;
    }
    .reject-button {
      background: #ff4d4f;
      color: #fff;
    }
    .cancel-button {
      background: #f8f9fb;
      color: #5a5f7d;
      margin-right: 8px;
      border: 1px solid #f1f2f6;
    }
  }
}
</style>
